import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, WritableSignal, signal, computed, Signal } from '@angular/core'
import * as fromCoreStore from '@app/core/store'
import * as fromUserStore from '@app/users/store'
import * as fromShopStore from '@app/shop/store'
import * as fromSidebarStore from '@app/sidebar/store'
import { Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { map, switchMap, takeUntil } from 'rxjs/operators'
import { User } from '@app/shared/models/user.model'
import { FOLLOW_UP_RESOURCE } from '@app/shared'
import { OpenModal } from '@app/modals/store'
import { ToastHelper } from '@app/core/services'
import { ShopShopOrder } from '@app/shop/models/shopOrder.model'
import { popupService } from '@app/shared/services/popup.service'
import { NgxPopperjsTriggers, NgxPopperjsPlacements, NgxPopperjsContentComponent } from 'ngx-popperjs'
import { maskitoTransform } from '@maskito/core'
import { debounceTime } from 'rxjs/operators';
import { ShopService } from '@app/shop/models/shopService.model'

interface _ShopService extends ShopService {
  _selected?: boolean
}

@Component({
    selector: 'app-shop-order-details',
    templateUrl: './shop-order-details.component.html',
    styleUrls: ['./shop-order-details.component.scss'],
    standalone: false
})
export class ShopOrderDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('selectedStatus', { static: false }) selectedStatus: NgxPopperjsContentComponent
  @ViewChild('_prevOdometer', { static: false }) _prevOdometer: NgxPopperjsContentComponent
  locked$: Observable<boolean>
  NgxPopperjsTriggers = NgxPopperjsTriggers
  NgxPopperjsPlacements = NgxPopperjsPlacements
  destroyed$ = new Subject<boolean>()
  currentUser: User
  currentShopOrder$: Observable<any>
  currentShopOrder: ShopShopOrder
  FOLLOW_UP_RESOURCE = FOLLOW_UP_RESOURCE
  users
  expenses

  services:WritableSignal<_ShopService[]> = signal([])
  readonly _services:Signal<_ShopService[]> = computed(() => this.services().filter(service => this.filterValue() ? (service.desc || '').toLowerCase()?.includes(this.filterValue()) : true))
  filterValue:WritableSignal<string> = signal('')

  currentFiles = []
  inputMasks = {
    phone: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  }
  type = 'phone';

  maskTransform = maskitoTransform

  serviceTypes = []

  statuses = [
    { _id: 'NEW', name: 'NEW' },
    { _id: 'ACTIVE', name: 'ACTIVE' },
    { _id: 'COMPLETED', name: 'COMPLETED' },
    { _id: 'CLOSED', name: 'CLOSED' },
  ]

  currentShopOrderStatuses = [
    { _id: 'NEW', name: 'NEW' },
    { _id: 'ACTIVE', name: 'ACTIVE' },
    { _id: 'COMPLETED', name: 'COMPLETED' },
    { _id: 'CLOSED', name: 'CLOSED' },
    { _id: 'ON_HOLD', name: 'ON-HOLD' },
  ]

  selectedServices:_ShopService[] = []
  loadingService = false

  currentServiceTypes

  editUnit = false

  totalLabors = 0
  totalParts = 0
  totalAmount = 0

  selectedService:_ShopService = null;
  sortableOptions

  isMouseDown: boolean = false;
  hasMoved: boolean = false;

  private descSubject = new Subject<{ event: Event, service: any }>();

  constructor(
    private store: Store<fromShopStore.State>,
    private autStore: Store<fromUserStore.State>,
    private coreStore: Store<fromCoreStore.State>,
    private notify: ToastHelper,
    private popupService: popupService,
    private cdr: ChangeDetectorRef
  ) {
    this.descSubject.pipe(
      debounceTime(600)
    ).subscribe(({ event, service }) => {
      this.updateServiceDesc(event, service);
    });
   }

  getMask = () => ({
    mask: this.inputMasks[this.type] || false
  })

  onMouseDown(event: MouseEvent) {
    this.isMouseDown = true;
    this.hasMoved = false;
  }

  onMouseMove(event: MouseEvent) {
    if (this.isMouseDown) {
      this.hasMoved = true;
    }
  }


  ngOnInit(): void {
    let oldServices:_ShopService[] = []
    this.sortableOptions = {
      filter: '.not-drag',
      preventOnFilter: false,
      onEnd: _ => {
        const newServicesArr:_ShopService[] = this._services().filter(s => s).map((s, index) => { return { ...s, index: index + 1 } })

        if (JSON.stringify(newServicesArr) !== JSON.stringify(oldServices.filter(s => s))) {
          this.store.dispatch(new fromShopStore.ShopServiceMultiUpdate({ _orderId: this.currentShopOrder._id, changes: newServicesArr as ShopService[] }))
        }
      },
      onStart: _ => {
        oldServices = [...this._services()]
      }
    }

    this.locked$ = this.store.select(fromShopStore.selectCurrentShopOrderId).pipe(
      switchMap(id => this.store.select(fromCoreStore.checkLockedItemLock(id))
        .pipe(
          map(lock => !lock.lockedByCurrentTab)
        )
      )
    )

    this.autStore
      .select(fromUserStore.getUser)
      .pipe(
        takeUntil(this.destroyed$),
        map(user => {
          this.currentUser = user
        })
      )
      .subscribe()

    this.store.select(fromShopStore.selectShopOrder).pipe(
      takeUntil(this.destroyed$),
      map(order => {
        if (order && order?._id) {
          let uniqueIds = {}
          let uniqueUnits = order.unit.filter(obj => {
            if (!uniqueIds[obj.name]) {
              uniqueIds[obj.name] = true
              return true
            }
            return false
          })
          order.unit = uniqueUnits
        }
        this.currentShopOrder = order
        this.cdr.detectChanges()
        this.coreStore.select(fromCoreStore.selectFilesByResource('SHOP_ORDER', [this.currentShopOrder?._id])).pipe(
          takeUntil(this.destroyed$),
          map(files => {
            this.currentFiles = files
          })
        )
          .subscribe()

      })
    )
      .subscribe()

    this.store.select(fromShopStore.selectAllShopServices).pipe(
      takeUntil(this.destroyed$),
      map(services => {
        if (services) {
          this.services.set(services)
          this.countAmounts(services)
          this.cdr.detectChanges()
        }
      })
    )
      .subscribe()

    this.store
      .select(fromShopStore.selectShopServicesTypes)
      .pipe(
        takeUntil(this.destroyed$),
        map(serviceTypes => {
          if (serviceTypes) {
            this.serviceTypes = serviceTypes
            this.currentServiceTypes = Object.entries(serviceTypes).map(([key, value]) => {
              return { _id: key, name: value }
            })
          }
        })
      )
      .subscribe()

    this.store.select(fromSidebarStore.selectAllUsers).pipe(
      takeUntil(this.destroyed$),
      map(users => {
        this.users = users
      })
    ).subscribe()

    this.store
      .select(fromShopStore.getLoading)
      .pipe(
        takeUntil(this.destroyed$),
        map(loading => {
          this.loadingService = loading
          return loading
        })
      )
      .subscribe()
  }

  updateShopOrder(order, populate = {}) {
    if (order?.unit?.driver) {
      delete order.unit.driver
    }
    this.store.dispatch(new fromShopStore.ShopOrderUpdate({ order, populate }))
  }

  countAmounts(services) {
    this.totalLabors = 0
    this.totalParts = 0
    this.totalAmount = 0
    services.map(s => {
      if (s?.amount) {
        if (s.type === 'LABOR') {
          this.totalLabors += Number(s?.amount) || 0
        } else {
          this.totalParts += Number(s?.amount) || 0
        }
        this.totalAmount += Number(s?.amount) || 0
      }
    })
  }

  toggleEditMode(service: any, event?) {
    if (event) {
      event.stopPropagation()
    }

    if (this.isMouseDown && !this.hasMoved) {
      this.selectedService = service
    }

    this.isMouseDown = false;
    this.hasMoved = false;
  }

  updateService(service) {
    this.store.dispatch(new fromShopStore.ShopServiceUpdate({ _orderId: this.currentShopOrder._id, ...service }))
  }

  mathFloor(amount) {
    return Number(Math.floor(amount))
  }

  onSelectAll() {
    if (this.selectedServices?.length === this._services()?.length) {
      this.selectedServices = []
      this.services.update(s => s.map(v => ({...v, _selected: false}) ))
    } else {
      this.selectedServices = this._services()
      this.services.update(s => s.map(v => this.selectedServices.find(_s => _s._id === v._id) ? ({...v, _selected: true}) : v ))
    }
  }

  updateUnit(data) {
    let change: any = {}
    for (let unit of this.currentShopOrder?.unit) {

      if (unit?._id == data?.value) {
        change = { _id: data._id, unit: { ...unit } }
        break
      }
    }

    for (let s of this.services()) {
      if (s?._id == data._id) {
        s.unit = { ...change.unit }
      }
    }

    this.updateService(change)
  }

  handleClick(event: Event) {
    event.stopPropagation()
  }

  setShop(shopId, shopName) {
    this.currentShopOrder.shop = { _id: shopId, name: shopName }
    this.updateShopOrder(this.currentShopOrder, { _shop: 1 })
  }

  removeShop(event) {
    this.currentShopOrder.shop = {}
    this.updateShopOrder(this.currentShopOrder)
  }

  setCustomer(id) {
    this.currentShopOrder.customerId = id
    this.updateShopOrder(this.currentShopOrder, { _customer: 1 })
  }

  setDriver(id) {
    this.currentShopOrder.driverId = id
    this.updateShopOrder(this.currentShopOrder, { _driver: 1, samsaraOdometer: 1 })
  }

  searchService(value:string) {
    this.filterValue.set(value?.toLowerCase());
  }

  removeCustomer(event) {
    this.currentShopOrder.customerId = null
    this.currentShopOrder.unit = []
    this.currentShopOrder.odometer = 0
    this.updateShopOrder(this.currentShopOrder, { _customer: 1, _unit: 1 })
  }

  async setOdometer(event) {

    if (event === '') {
      event = 0
    }
    event = Number(event)

    if (event === this.currentShopOrder?.odometer || (event == 0 && !this.currentShopOrder?.odometer)) {
      return
    }

    if (this._prevOdometer) {
      this._prevOdometer.hide()
    }
    this.currentShopOrder._prevOdometer = this.currentShopOrder._prevOdometer || 0
    if (event < this.currentShopOrder._prevOdometer && (await this.popupService.confirm(`You set odometer less than on the previous order (${this.currentShopOrder._prevOdometer})`))) {
      this.currentShopOrder.odometer = event
      this.updateShopOrder(this.currentShopOrder, { samsaraOdometer: 1 })
      return
    } else if (event >= this.currentShopOrder._prevOdometer) {
      this.currentShopOrder.odometer = event
      this.updateShopOrder(this.currentShopOrder, { samsaraOdometer: 1 })
    } else {
      return
    }
  }

  patchDesc(event) {
    this.currentShopOrder.desc = event.target.value
    this.updateShopOrder(this.currentShopOrder)
  }

  setMechanic(contact) {
    let mechanic = {
      _id: contact._id,
      name: contact.name
    }
    this.currentShopOrder['mechanic'] = mechanic
    this.updateShopOrder(this.currentShopOrder)
  }

  setUnit({ name, _id, equipmentType }) {
    let unit = {
      name,
      _id,
      unitType: equipmentType
    }
    this.currentShopOrder['unit'].push(unit)
    const data = this.currentShopOrder
    delete data.odometer
    this.updateShopOrder({ _id: data._id, unit: data.unit }, { _unit: 1, samsaraOdometer: 1 })
  }

  replaceUnit({ name, _id, equipmentType }) {
    let unit = {
      name,
      _id,
      unitType: equipmentType
    }
    const data = { _id: this.currentShopOrder._id, odometer: 0, unit: [unit] }
    this.editUnit = false
    this.updateShopOrder(data, { _unit: 1, samsaraOdometer: 1 })
    setTimeout(() => {
      this.coreStore.dispatch(
        new fromShopStore.GetShopOrderServices({ _orderId: this.currentShopOrder._id })
      )
    }, 1000)
  }

  removeUnit(_id) {
    this.updateShopOrder({
      ...this.currentShopOrder,
      unit: this.currentShopOrder?.unit.filter(unit => unit._id !== _id),
      odometer: 0
    }, { _unit: 1, samsaraOdometer: 1 })
  }

  setStatus(event) {
    let status = event
    event = this.currentShopOrder?.status
    if (status === 'ACTIVE' || status === 'COMPLETED' || status === 'CLOSED') {
      if (!this.currentShopOrder?.driverId) {
        return this.notify.error('Please Select Driver')
      }
      if (!this.currentShopOrder?.arrivalDate || !this.currentShopOrder?.departureDate) {
        return this.notify.error('Please Set Arrival/Departure Date')
      }
      if (!this.currentShopOrder?.odometer) {
        return this.notify.error('Please Select Odometer')
      }
    }
    if (status === 'COMPLETED' || status === 'CLOSED') {
      if (this.services().find(s => s?.status !== "COMPLETED")) {
        return this.notify.error('Services must be completed')
      }
      if (!this.currentShopOrder?.completedAt) {
        return this.notify.error('Please Set Completed Date')
      }
    }
    if (status === 'CLOSED') {
      if (this.totalAmount === 0) {
        return this.notify.error('Please Set Amounts')
      }
      if (!this.currentShopOrder?.accounting?.invoiceNumber || !this.currentShopOrder?.accounting?.invoiceDate) {
        return this.notify.error('Please Set Invoice Number/Date')
      }

      if (!this.currentFiles.length) {
        return this.notify.error('Please Upload Invoice')
      }
    }
    event = status
    this.currentShopOrder.status = status
    this.updateShopOrder(this.currentShopOrder)

    if (this.selectedStatus) {
      this.selectedStatus.hide()
    }
  }

  setAccounting(field, event) {
    if (!event) return
    this.currentShopOrder.accounting = { ...this?.currentShopOrder?.accounting }
    this.currentShopOrder.accounting[field] = event
    this.updateShopOrder(this.currentShopOrder)
  }

  deleteShopOrder() {
    this.store.dispatch(new fromShopStore.DeleteShopOrder({ _id: this.currentShopOrder._id }))
  }

  generatePdf() {
    this.store.dispatch(new fromShopStore.GetShopOrderPDF({ order: this.currentShopOrder, query: {} }))
  }

  generateBlank() {
    this.store.dispatch(new fromShopStore.GetShopOrderPDF({ order: this.currentShopOrder, query: { _blank: 1 } }))
  }

  openPreview = (service) =>
    this.store.dispatch(
      new OpenModal({
        type: 'SHOP_SERVICE',
        props: {
          _id: service?._id,
          _orderId: this.currentShopOrder._id
          // windowClass : "shop-details-modal"
        },
      })
    )

  addService() {
    this.store.dispatch(new fromShopStore.CreateShopService({item: { _orderId: this.currentShopOrder._id, status: "NEW", type: 'LABOR', index: this.services()?.length ? this.services()?.length + 1 : 1 }, order: this.currentShopOrder}))
  }

  // updateServiceDesc(event, service) {
  //   this.updateService({ _id: service._id, desc: event.target.value })
  // }

  updateServiceDesc(event: Event, service: any) {
    this.updateService({ _id: service._id, desc: (event.target as HTMLTextAreaElement).value });
  }

  onDescChange(event: Event, service: any) {
    this.descSubject.next({ event, service });
  }

  setServiceMechanic(contact, service) {
    let mechanic = {}
    if (contact) {
      mechanic = {
        _id: contact._id,
        name: contact.name
      }
    }

    service = { _id: service._id, mechanic }
    this.updateService(service)
  }

  selectService = (service, event?) => {
    if (event) {
      event.stopPropagation()
    }
    if (this.selectedServices.findIndex(x => x._id === service._id) < 0) {
      this.selectedServices.push(service)
      this.services.update((s) => s.map(v => service._id === v._id ? ({...v, _selected: true }) : v))
    }
    else {
      this.selectedServices = this.selectedServices.filter(x => x._id != service._id)
      this.services.update((s) => s.map(v => service._id === v._id ? ({...v, _selected: false }) : v))
    }
  }

  unselectAllSelectedService() {
    this.selectedServices = []
    this.services.update(s => s.map(v => ({...v, _selected: false}) ))
  }

  setServicesStatus(event) {
    if (!event) return

    this.selectedServices.map(s => {
      s.status = event
      return s
    })
    this.store.dispatch(new fromShopStore.ShopServiceMultiUpdate({ _orderId: this.currentShopOrder._id, changes: this.selectedServices }))
    this.unselectAllSelectedService()
  }

  moveServices() {
    const servicesIds = this.selectedServices.map(s => s._id)
    const data:Partial<ShopShopOrder> = {
      customerId: this?.currentShopOrder?.customerId,
      shop: this?.currentShopOrder?.shop,
      unit: this?.currentShopOrder?.unit,
      driverId: this?.currentShopOrder?.driverId,
    }
    this.coreStore.dispatch(
      new fromShopStore.CreateShopOrder({ ...data, moveServices: servicesIds })
    )
    this.unselectAllSelectedService()
  }

  clone() {
    const data = {
      customerId: this?.currentShopOrder?.customerId,
      shop: this?.currentShopOrder?.shop,
      unit: this?.currentShopOrder?.unit,
    }
    this.filterValue.set('')

    this.coreStore.dispatch(
      new fromShopStore.CreateShopOrder(data)
    )
  }

  async deleteAllSelectedService() {
    if (await this.popupService.confirm('Are you sure you want to delete selected services?')) {
      this.store.dispatch(new fromShopStore.DeleteManyShopServices({deleteData: { ids: this.selectedServices.map(t => t._id), _orderId: this.currentShopOrder._id }, order: this.currentShopOrder}))
      this.services.update(services => {
        return services
        .filter(s => s)
        .map((s, index) => { return { ...s, index: index + 1 } })
      })
      this.store.dispatch(new fromShopStore.ShopServiceMultiUpdateSuccess(this.services()))
      this.selectedServices = []
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
